import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import Row from "../layout/row"
import { Link } from "gatsby"
import House from "../../assets/house.svg"
import Favorite from "../../assets/favorite.svg"
import Ask from "../../assets/ask.svg"

const ButtonSection = (props) => {
  const ButtonStyle = css`
    background-color: white;
    padding: 1.5rem;
    font-size: 1.1rem;
    flex-basis: 33%;
    margin-right: 1.5rem;
    text-align: left;
    text-decoration: none;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    span {
      display: block;
      font-weight: 500;
      font-size: 1.2rem;
      transition: color 0.3s ease-in-out 0s;
    }
    &:hover {
      span {
        color: #c4281d;
      }
    }

    &:last-of-type {
      margin-right: 0px;
    }
  `
  const ButtonStyled = styled(Link)`
    ${ButtonStyle}
    ${props => props.theme.tablet} {
      margin: 0 0 1rem 0;
    }
  `

  const ExternalLink = styled.a`
    ${ButtonStyle}
    ${props => props.theme.tablet} {
      margin: 0 0 1rem 0;
    }
  `

  const ButtonsWrapper = styled.div`
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;

    ${props => props.theme.tablet} {
      flex-direction: column;
      margin: 1rem 0;
    }
    p {
      font-size: 0.9rem;
    }
  `
  const IconStyle = css`
    width: 25px;
    height: 25px;
    fill: #c4281d;
  `

  const HouseIcon = styled(House)`
    ${IconStyle}
  `

  const AskIcon = styled(Ask)`
    ${IconStyle}
  `

  const FavoriteIcon = styled(Favorite)`
    ${IconStyle}
  `

  const TitleWrapper = styled.div`
    display: flex;
    margin-bottom: 1.5rem;
    justify-content: space-between;
  `

  const Annotation = styled.p`
    color: ${props => props.theme.colors.secondary};
  `
  
  return (
    <Row>
      <ButtonsWrapper>
        <ExternalLink
          href="https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D1%81%D0%BF%D0%B8%D1%81%D0%BE%D0%BA-%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82%D0%BE%D0%B2/%D1%81%D0%BF%D0%B8%D1%81%D0%BE%D0%BA?bounds=&subject=59"
          target="_blank"
          rel="noreferrer"
        >
          <TitleWrapper>
            <span>Проблемные объекты</span>
            <HouseIcon />
          </TitleWrapper>
          <Annotation>
            Информация о всех проблемных объектах жилищного строительства
            Пермского края
          </Annotation>
        </ExternalLink>
        <ButtonStyled to="/holders/">
          <TitleWrapper>
            <span>Частые вопросы</span>
            <FavoriteIcon />
          </TitleWrapper>
          <Annotation>Ответы на часто задаваемые гражданами вопросы</Annotation>
        </ButtonStyled>
        <ButtonStyled to="/contact-us/">
          <TitleWrapper>
            <span>Задать вопрос</span>
            <AskIcon />
          </TitleWrapper>
          <Annotation>
            Здесь вы можете обратиться с вопросом в адрес Фонда
          </Annotation>
        </ButtonStyled>
      </ButtonsWrapper>
    </Row>
  )
}

export default ButtonSection
