import { graphql, useStaticQuery } from "gatsby"

const useNews = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        posts(first: 3) {
          nodes {
            date
            slug
            title
          }
        }
      }
    }
  `)

  return data.wordPress.posts.nodes
}


export default useNews
