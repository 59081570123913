import React from "react"
import styled from "@emotion/styled"
import Row from "../layout/row"

import Map from "../map";


const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  h2,
  h3 {
    font-weight: 500;
  }
  h3 {
    margin-top: 0;
    font-size: 1.3rem;
  }
`

const HeadingWrapper = styled("div")`
  margin: 0 0 0rem 0;
  display: flex;
  flex-direction: column;

  /* &:after {
    content: "";
    width: 100%;
    border-bottom: solid 2px #677b82;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  } */

  span {
    font-size: 1.5rem;
    font-weight: 600;
    width: auto;
    display: inline-block;
    margin-bottom: 0.5rem;
    ${props => props.theme.tablet} {
      padding: 0;
    }
  }
`

const Annotation = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 0.9rem;
`

const MapSection = () => {
  return (
    <>
      <Row>
        <Wrapper>
          <HeadingWrapper>
            <span>Карта объектов</span>
            <Annotation>
              Объекты, находящиеся в работе фонда защиты прав граждан-участников
              строительства Пермского края.
            </Annotation>
          </HeadingWrapper>
        </Wrapper>
        <Map />
      </Row>
    </>
  )
}

export default MapSection
