import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import Row from "../layout/row"
import { Link } from "gatsby"
import { toLocalDate } from "../../helpers"
import useNews from "../../hooks/use-news"

const NewsWrapper = styled.div`
  display: flex;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${props => props.theme.colors.line};
  ${props => props.theme.tablet} {
    flex-direction: column;
  }
`

const ColumnStyle = css`
  flex: 1 1 0%;
`

const MainColumn = styled.div`
  ${ColumnStyle}
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props => props.theme.tablet} {
    margin-right: 0;
  }
`

const SecondaryColumn = styled.div`
  ${ColumnStyle}
`

const Title = styled.span`
  font-size: 1.5rem;
  display: block;
  margin-bottom: 1.5rem;
  font-weight: 600;
`

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: black;
  }
  &:hover {
    div {
      span {
        color: ${props => props.theme.colors.activeLink};
        transition: color 0.3s ease-in-out 0s;
      }
    }
  }
`

const SecondaryArticle = styled(ArticleWrapper)`
  margin-bottom: 1.5rem;
`

const NewsDate = styled.span`
  font-size: 1.1rem;
  font-weight: 200;
  display: block;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.secondary};
  ${props => props.theme.tablet} {
    font-size: 0.9rem;
  }
`
const MainNews = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  display: block;
  ${props => props.theme.tablet} {
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
  }
`

const SecondaryNews = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
`

const SecondaryDate = styled(NewsDate)`
  font-size: 0.9rem;
  font-weight: 200;
  margin-bottom: 0.5rem;
`

const News = () => {
  const news = useNews()
  return (
    <Row>
      <NewsWrapper>
        <MainColumn>
          <Title>Новости</Title>
          <ArticleWrapper>
            <Link to={`/news/${news[0].slug}`}>
              <NewsDate>{toLocalDate(news[0].date)}</NewsDate>
              <div>
                <MainNews dangerouslySetInnerHTML={{ __html: news[0].title }} />
              </div>
            </Link>
          </ArticleWrapper>
        </MainColumn>
        <SecondaryColumn>
          <SecondaryArticle>
            <Link to={`/news/${news[1].slug}`}>
              <SecondaryDate>{toLocalDate(news[1].date)}</SecondaryDate>
              <div>
                <SecondaryNews
                  dangerouslySetInnerHTML={{ __html: news[1].title }}
                />
              </div>
            </Link>
          </SecondaryArticle>
          <SecondaryArticle>
            <Link to={`/news/${news[2].slug}`}>
              <SecondaryDate>{toLocalDate(news[2].date)}</SecondaryDate>
              <div>
                <SecondaryNews
                  dangerouslySetInnerHTML={{ __html: news[2].title }}
                />
              </div>
            </Link>
          </SecondaryArticle>
          <div>
            <Link to="/news/page/1">Все новости &rarr;</Link>
          </div>
        </SecondaryColumn>
      </NewsWrapper>
    </Row>
  )
}

export default News
