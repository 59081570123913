import React from "react"
import Layout from "../components/layout"
import SearchSection from "../components/indexPage/search/index"
import ButtonSection from "../components/indexPage/buttons"
import MapSection from "../components/indexPage/map"
import NewsSection from "../components/indexPage/news"

const Index = ({ location }) => {
  return (
    <Layout>
      <SearchSection focus={location.state} />
      <ButtonSection />
      <NewsSection />
      <MapSection />
    </Layout>
  )
}

export default Index
