import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import BackgroundImage from "gatsby-background-image"
import Row from "../../layout/row"
import Input from "./input"

const ImageBackground = styled(BackgroundImage)`
  background-position: bottom;
  height: 50vh;
  min-height: 500px;
  background-size: cover;
  ${props => props.theme.tablet} {
    height: auto;
    min-height: 0;
  }
`
const TextBox = styled("div")`
  background-color: rgba(50, 50, 50, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding: 3.5rem;
  ${props => props.theme.tablet} {
    padding: 3rem 0.5rem;
  }
  h1 {
    color: ${props => props.theme.colors.navLink};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 2rem;
    width: 60%;
    ${props => props.theme.tablet} {
      width: 100%;
      font-size: 1rem;
      margin-bottom: 0;
      text-align: center;
    }
  }
`

const SearchSection = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "index.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Row>
      <ImageBackground Tag="section" fluid={image.sharp.fluid} fadeIn="soft">
        <TextBox>
          <h1>
            Актуальная информация об объектах жилищного строительства в работе
            фонда
          </h1>
          <Input />
        </TextBox>
      </ImageBackground>
    </Row>
  )
}

export default SearchSection
