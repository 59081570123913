import React, { useState, useRef, useEffect } from "react"
import SearchIcon from "../../../assets/search.svg"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import fetch from "isomorphic-fetch"
import { polyfill } from "es6-promise"
polyfill()

const InputWrapper = styled("div")`
  position: relative;
  padding: 0.5rem 1rem 0;
  ${props => props.theme.tablet} {
    padding: 0;
  }
  border-radius: 0px;
  background: white;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  border-color: transparent;
  transition: border-color 0.5s linear 0s;
  ${({ active }) =>
    active &&
    `
    border-color: #c4281d;
  `}
`

const SearchWrapper = styled.div`
  position: relative;
`

const Result = styled.ul`
  position: absolute;
  list-style: none;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  margin-top: 4px;
  max-height: 430px;
  display: block;
  padding: 0.5rem 1rem 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(202, 204, 223);
  border-image: initial;
  ul {
    list-style: none;
  }

  ${props => props.theme.tablet} {
    padding: 0.5rem;
    ul {
      padding-inline-start: 0.1rem;
      margin-bottom: 0;
      font-size: 0.9rem;
    }
  }
`

const ResultItem = styled.li`
  padding: 0.5rem 0;
  margin-top: 0;

  ${props => props.theme.tablet} {
    padding: 0.2rem 0 0 0.2rem;
    border-bottom: 1px solid ${props => props.theme.colors.line};
  }
`

const Spiner = styled.div`
  .loader,
  .loader:before,
  .loader:after {
    background: ${props => props.theme.colors.activeLink};
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  .loader {
    position: absolute;
    top: 37%;
    right: 1.8rem;
    color: ${props => props.theme.colors.activeLink};
    text-indent: -9999em;
    font-size: 0.3rem;
    transform: translateZ(0);
    animation-delay: -0.16s;
    ${props => props.theme.tablet} {
      right: 1rem;
      top: 40%;
      font-size: 0.2rem;
    }
  }
  .loader:before,
  .loader:after {
    position: absolute;
    top: 0;
    content: "";
  }
  .loader:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
`

const StyledInput = styled("input")`
  width: 100%;
  border: none;
  height: 3rem;
  color: black;
  font-size: 1rem;
  outline: none;
  margin-top: 1rem;
  font-family: inherit;
  ${props => props.theme.tablet} {
    height: 3rem;
    margin-top: 0;
    padding: 0 0.5rem;
  }
`

const SearchIconWrapper = styled("div")`
  position: absolute;
  top: 50%;
  right: 0rem;
  transform: translateY(-50%);
  background-color: white;
  padding: 1.2rem;
  cursor: pointer;
  ${props => props.theme.tablet} {
    padding: 0rem;
    right: 0.3rem;
  }
`

const SearchIconStyled = styled(SearchIcon)`
  width: 1.3rem;
  height: 100%;
  fill: grey;
`

const LabelForInput = styled("label")`
  ${props => props.theme.tablet} {
    display: none;
  }
  color: grey;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 35%;
  left: 1.1rem;
  transform: translateY(0);
  cursor: text;
  transition: transform 0.3s linear;
  ${({ active }) =>
    active &&
    `
    transform: translateY(-18px)
  `}
`

const Input = props => {
  const [selected, setSelected] = useState(false)
  const wrapperRef = useRef(null)
  const textInput = useRef(null)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [searchResult, setSearchResult] = useState([])

  function handleClick() {
    if (searchText) {
      return
    }
    if (selected) {
      setSelected(!selected)
      textInput.current.blur()
      return
    }
    setSelected(!selected)
    textInput.current.focus()
  }

  function useOutsideAlerter(ref, searchText) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (searchText) {
            setLoaded(false)
            return
          }
          setSelected(false)
          setLoaded(false)
          textInput.current.blur()
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, searchText])
  }

  useOutsideAlerter(wrapperRef, searchText)

  function searchRequest(str) {
    fetch(`${process.env.SEARCH_URL}${str}`)
      .then(response => response.json())
      .then(data => data.map(item => fetch(item._links.self[0].href)))
      .then(requests => Promise.all(requests))
      .then(responses => Promise.all(responses.map(r => r.json())))
      .then(results => {
        setSearchResult(() => results)
        setLoading(loading => !loading)
        setLoaded(true)
      })
      .catch(err => console.error(err))
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      setLoading(loading => !loading)
      searchRequest(searchText)
    }
  }

  function handleIconClick() {
    setLoading(loading => !loading)
    searchRequest(searchText)
  }

  function handleTextInput(e) {
    setSearchText(e.target.value)
  }

  function resultsRender() {
    let results
    if (searchResult.length > 0) {
      const newsArray = searchResult.filter(result => result.type === "post")
      const documentsArray = searchResult.filter(
        result => result.type === "documents"
      )
      const objectsArray = searchResult.filter(
        result => result.type === "objects"
      )
      const news = newsArray.map(result => (
        <ResultItem key={result.id}>
          <Link
            to={`/news/${result.slug}`}
            dangerouslySetInnerHTML={{ __html: result.title.rendered }}
          />
        </ResultItem>
      ))
      const documents = documentsArray.map(result => (
        <ResultItem key={result.id}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${result.acf.document}`}
            dangerouslySetInnerHTML={{ __html: result.title.rendered }}
          />
        </ResultItem>
      ))
      const objects = objectsArray.map(result => (
        <ResultItem key={result.id}>
          <Link
            to={`/objects/${result.slug}`}
            dangerouslySetInnerHTML={{ __html: result.title.rendered }}
          />
        </ResultItem>
      ))

      results = (
        <React.Fragment>
          {news.length > 0 && (
            <li>
              <div>Новости</div>
              <ul>{news}</ul>
            </li>
          )}
          {documents.length > 0 && (
            <li>
              <div>Документы</div>
              <ul>{documents}</ul>
            </li>
          )}
          {objects.length > 0 && (
            <li>
              <div>Объекты</div>
              <ul>{objects}</ul>
            </li>
          )}
        </React.Fragment>
      )
    } else {
      results = <ResultItem>Данных нет</ResultItem>
    }
    return results
  }

  return (
    <SearchWrapper ref={wrapperRef}>
      <InputWrapper active={selected}>
        <StyledInput
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          active={selected}
          spellcheck="false"
          onClick={handleClick}
          value={searchText}
          onChange={handleTextInput}
          onKeyPress={handleKeyPress}
          ref={textInput}
          type="text"
          id="search-input"
        />
        <LabelForInput htmlFor="search-input" active={selected}>
          Поиск по застройщику, ЖК, ЖСК, адресу, новости, документу.
        </LabelForInput>
        {loading ? (
          <Spiner>
            <div className="loader">Loading...</div>
          </Spiner>
        ) : (
          <SearchIconWrapper onClick={handleIconClick}>
            <SearchIconStyled />
          </SearchIconWrapper>
        )}
      </InputWrapper>
      {loaded && <Result>{resultsRender()}</Result>}
    </SearchWrapper>
  )
}

export default Input
